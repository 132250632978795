import React from 'react';
import { Container } from 'react-bootstrap';
import './YtTwo.css';

function FbVideo2() {
  // Facebook video URL
  const videoUrl = 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fpbaesports%2Fvideos%2F481549874886109%2F&show_text=false&width=560&t=0';

  return (
    <div className="fb-div">
      <Container>
        <div className="embed-responsive embed-responsive-16by9" style={{ textAlign: 'center', marginTop: '5%', marginBottom: '5%' }}>
          <iframe
            className="embed-responsive-item"
            src={videoUrl}
            title="Facebook video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            width="740" // Adjust width here
            height="415" // Adjust height here
          ></iframe>
        </div>
      </Container>
    </div>
  );
}

export default FbVideo2;
